import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer-container">
      <section className="footer-column">
        <div className="footer-column-logos">
          <Link to="https://www.innovaud.ch/fr/" target="_blank">
            <img src="Innovaud.jpg" alt="Innovaud" />
          </Link>
          <Link to="https://www.iata.org/" target="_blank">
            <img src="IATA-Logo.png" alt="IATA" />
          </Link>
        </div>
      </section>
      <section className="footer-column">
        <p className="footer-column-heading">{t('contactUsTitle')}</p>
        <p className="footer-column-text">
          <span>CTM Europe S.A.</span>
          <span>{t('footerOfficeHours')}</span>
          {t('footerOfficeDays')} 9:00-18:00
          <span>{t('footerLunchBreak')}</span> 13:00-14:00
          <span>{t('footerAddress')}</span>
          Chemin du Canal 5,
          <br />
          Nyon 1260, Switzerland
          <span>Tel</span> +41 79 8255812
        </p>
      </section>
      <section className="footer-column">
        <p className="footer-column-heading">{t('footerLinks')}</p>
        <p className="footer-column-text">
          <span>
            <Link to="https://www.worldtravelguide.net/">
              {t('footerWorldTravelGuide')}
            </Link>
          </span>
          <span>
            <Link to="https://artsandculture.google.com/">
              {t('footerWorldMuseums')}
            </Link>
          </span>
          <span>
            <Link to="https://ticketmaster.com/">{t('footerTickets')}</Link>
          </span>
        </p>
      </section>
    </div>
  );
}

export default Footer;
