import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector/cjs';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          homeTitle: 'Your trusted and knowledgeable travel advisor',
          cardItemDesc: 'Learn more about our company',
          aboutUsTitle: 'About us',
          companyOverview: 'Company overview',
          companyOverviewText:
            ' CTM Europe S.A. is an experienced travel company providing a wide range of services to cater for private and business travel needs. CTM Europe is a trusted and knowledgeable travel advisor. Our ‘insider knowledge’ provides customers with an unmatched access to a complete range of high end travel and concierge services. We offer a complete servicing package that is tailor made to focus on maximising traveller comfort, satisfaction and safety.',

          ourServiceCommitment: 'Our service commitment',
          ourServiceCommitmentText:
            '  CTM Europe S.A. makes a commitment to always provide customer-tailored solutions based on individual and corporate customers’ priorities and requirements. We offer:',
          ourServiceCommitmentTextListItem1:
            'A personalised dedicated booking service',
          ourServiceCommitmentTextListItem2:
            'Multiple options for all itineraries',
          ourServiceCommitmentTextListItem3:
            'Flexible and intelligent travel solutions',
          dedicatedPersonnel: 'Dedicated personnel',
          dedicatedPersonnelText:
            'CTM Europe’s pride is its people. We provide an exceptional service that is proactive and personalised. We engage with our clients commercially and emotionally to align with their objectives. Whether your trip is for business or for leisure CTM travel agents will go an extra mile to meet your needs.',
          advancedTravelTechnology: 'Advanced travel technology',
          advancedTravelTechnologyText:
            'CTM Europe S.A. employ advanced booking tools and ‘best-in-class’ technologies to ensure seamless and efficient service, we embrace both advanced and emerging technologies to achieve the best results. CTM Europe enjoys an extremely strong network of relationships with suppliers of travel services, whether hotels, airlines, ground transportation companies or specialised tour operators. Selected supplier relationships are negotiated to establish the most competitive service sourcing on the market.',
          contactUsText:
            'Ready to transform the way you travel? CTM Europe SA is here to answer all your questions and assist you in planning your next trip. Contact us today to find out how we can help you achieve your travel goals.',
          contactUsTitle: 'Contact us',
          contactUsName: 'Name',
          contactUsSendButton: 'Send',
          contactSuccessMessage: 'Message sent',
          contactFailMessage: 'Something went wrong',
          footerOfficeHours: 'Office hours',
          footerOfficeDays: 'Monday - Friday',
          footerLunchBreak: 'Lunch break',
          footerAddress: 'Address',
          footerLinks: 'Links',
          footerWorldTravelGuide: 'World Travel Guide',
          footerWorldMuseums: 'World Museums',
          footerTickets: 'Tickets to music and sports events',
        },
      },
      fr: {
        translation: {
          homeTitle:
            "Votre partenaire de confiance pour vos voyages d'affaires",
          cardItemDesc: 'En savoir plus sur notre entreprise',
          aboutUsTitle: 'A propos de nous',
          companyOverview: 'Une expertise inégalée au service de vos voyages',
          companyOverviewText:
            " CTM Europe SA se distingue par son rôle de conseiller avisé et de source fiable d'informations. Notre équipe d'experts vous fait bénéficier d'un accès privilégié à une gamme complète et exclusive de services de conciergerie. Chaque détail de votre voyage est soigneusement pensé et planifié pour optimiser votre confort, votre sécurité et votre satisfaction. Que vous voyagiez pour affaires ou pour le plaisir, nous vous offrons un ensemble de services sur mesure qui dépasse vos attentes.",
          ourServiceCommitment: 'Un engagement total à votre service',
          ourServiceCommitmentText:
            " Chez CTM Europe SA, notre priorité est de vous offrir des solutions parfaitement adaptées à vos besoins individuels et professionnels. Nous comprenons que chaque voyageur est unique, et nous mettons un point d'honneur à personnaliser notre offre en fonction de vos préférences et de vos exigences spécifiques. Nos services incluent :",
          ourServiceCommitmentTextListItem1:
            'Un service de réservation dédié et personnalisé',
          ourServiceCommitmentTextListItem2:
            'De multiples options pour tous les itinéraires',
          ourServiceCommitmentTextListItem3:
            'Solutions de voyage flexibles et intelligentes',
          dedicatedPersonnel: 'Un service client exceptionnel',
          dedicatedPersonnelText:
            " Nous sommes particulièrement fiers des personnes qui composent CTM Europe SA. Notre équipe est formée pour fournir un service exceptionnel, à la fois proactif et personnalisé.Nous croyons fermement que la relation client ne se limite pas à un simple échange commercial. Nous établissons des liens solides avec nos clients, basés sur une compréhension approfondie de leurs objectifs et de leurs besoins. Que vous planifiez un voyage d'affaires crucial ou des vacances bien méritées, les spécialistes de CTM Europe SA s'engagent à mettre tout en œuvre pour que votre expérience soit irréprochable.",
          advancedTravelTechnology:
            'Des technologies de pointe pour un voyage optimisé',
          advancedTravelTechnologyText:
            "Afin de vous offrir une expérience de voyage fluide et efficace, CTM Europe SA s'appuie sur des outils de réservation avancés et les meilleures technologies disponibles. Nous intégrons les dernières innovations technologiques pour assurer des prestations de haute qualité, tout en explorant continuellement de nouvelles solutions pour rester à la pointe de l'industrie. Grâce à notre réseau étendu de partenaires, comprenant des hôtels de luxe, des compagnies aériennes renommées, des services de transport au sol et des voyagistes spécialisés, CTM Europe SA est en mesure de vous proposer des offres compétitives et des services exclusifs. Nos partenariats sont soigneusement négociés pour garantir que vous bénéficiez du meilleur rapport qualité-prix.",
          contactUsText:
            "Prêt à transformer votre façon de voyager? CTM Europe SA est à votre disposition pour répondre à toutes vos questions et vous accompagner dans la planification de votre prochain voyage. Contactez-nous dès aujourd'hui pour découvrir comment nous pouvons vous aider à atteindre vos objectifs de voyage.",
          contactUsTitle: 'Contactez-nous',
          contactUsName: 'Nom',
          contactUsSendButton: 'Envoyer',
          contactSuccessMessage: 'Message envoyé',
          contactFailMessage: 'Something went wrong',
          footerOfficeHours: 'Horaires de travail',
          footerOfficeDays: 'Lundi - Vendredi',
          footerLunchBreak: 'Pause déjeuner',
          footerAddress: 'Adresse',
          footerLinks: 'Liens',
          footerWorldTravelGuide: 'World Travel Guide',
          footerWorldMuseums: 'Musées du monde',
          footerTickets: 'Billets pour des événements de musique et de sport.',
        },
      },
    },
  });
