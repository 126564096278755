import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          {/* <Route path="services" element={<Services />}></Route>
          <Route path="products" element={<Products />}></Route>*/}

          <Route path="about-us" element={<AboutUs />}></Route>

          <Route path="contact-us" element={<ContactUs />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
