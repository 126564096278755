import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import SwitchToggle from './Switch';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const { t } = useTranslation();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 1055) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="rbt_logo.png" alt="" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <Link
                to="/about-us"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {t('aboutUsTitle')}
              </Link>
            </li>{' '}
            {/*{' '}
            <li className="nav-item">
              <Link
                to="/services"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/products"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>
            ––>*/}
            <li>
              <Link
                to="/contact-us"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                {t('contactUsTitle')}
              </Link>
            </li>
          </ul>{' '}
          {button && (
            <Button linkAddr="/contact-us" buttonStyle="btn--outline">
              {t('contactUsTitle')}
            </Button>
          )}{' '}
        </div>
        <div className="btn-lang-toggle">
          <SwitchToggle />
        </div>
      </nav>
    </>
  );
}

export default Navbar;
