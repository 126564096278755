import React from 'react';
import { Button } from './Button';
import './HeroSection.css';
import { useTranslation } from 'react-i18next';

function HeroSection() {
  const { t } = useTranslation();
  return (
    <div className="hero-container">
      <video src="/testing.mp4" autoPlay loop muted />
      <h1>CTM Europe</h1>
      <p> {t('homeTitle')}</p>
      <div className="hero-btns">
        <Button
          linkAddr="/about-us"
          className="btns"
          buttonStyle="btn--outline"
          buttonSize="btn--large"
        >
          {t('aboutUsTitle')}
        </Button>
        <Button
          linkAddr="/contact-us"
          className="btns"
          buttonStyle="btn--primary"
          buttonSize="btn--large"
        >
          {t('contactUsTitle')}
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
