import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../TextChunk.css';
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-us">
        <h1 className="page-title">{t('aboutUsTitle')}</h1>
      </div>
      <div className="about_us-container">
        <section className="about_us-section">
          <h2 className="text-title">{t('companyOverview')}</h2>{' '}
          <div className="about_us-text">
            {t('companyOverviewText')}
            <div className="about_us-img">
              <img src="overview.jpg" alt=""></img>
            </div>
          </div>
        </section>
        <section className="about_us-section">
          <h2 className="text-title">{t('ourServiceCommitment')}</h2>
          <div className="about_us-text">
            <div className="about_us-img">
              <img src="Handshake.jpg" alt=""></img>
            </div>
            <div className="about_us-text_with_list">
              {t('ourServiceCommitmentText')}
              <ul>
                <li>{t('ourServiceCommitmentTextListItem1')}</li>
                <li>{t('ourServiceCommitmentTextListItem2')}</li>
                <li>{t('ourServiceCommitmentTextListItem3')}</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="about_us-section">
          <h2 className="text-title">{t('dedicatedPersonnel')} </h2>
          <div className="about_us-text">
            {t('dedicatedPersonnelText')}
            <div className="about_us-img">
              <img src="teamwork.jpg" alt=""></img>
            </div>
          </div>
        </section>
        <section className="about_us-section">
          <h2 className="text-title"> {t('advancedTravelTechnology')}</h2>
          <div className="about_us-text">
            <div className="about_us-img">
              <img src="traveling.jpg" alt=""></img>
            </div>
            {t('advancedTravelTechnologyText')}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}
