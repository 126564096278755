import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import { useTranslation } from 'react-i18next';
function Cards() {
  const { t } = useTranslation();
  return (
    <div className="cards">
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="card__items">
            <CardItem
              src="ctm example.gif"
              text={t('cardItemDesc')}
              label={t('aboutUsTitle')}
              path="/about-us"
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
