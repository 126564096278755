import { useState } from 'react';
import React from 'react';
import emailjs from '@emailjs/browser';
import './ContactForm.css';
import { Trans, useTranslation } from 'react-i18next';
const ContactForm = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY,
      )
      .then(
        (result) => {
          setStateMessage(t('contactSuccessMessage'));
          setIsSubmitting(false);
          setTimeout(() => {}, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage(t('contactFailMessage'));
          setIsSubmitting(false);
          setTimeout(() => {}, 5000); // hide message after 5 seconds
        },
      );

    // Clears the form after sending the email
  };
  return (
    <section className="contact">
      <div className="contact_text">{t('contactUsText')}</div>
      <form onSubmit={sendEmail}>
        <div className="input-box">
          <label>{t('contactUsName')}</label>
          <input className="field" type="text" name="user_name" />
        </div>
        <div className="input-box">
          <label>Email</label>
          <input className="field" type="email" name="user_email" />
        </div>
        <div className="input-box">
          <label>Message</label>
          <textarea className="field mess" name="message" />
        </div>
        <button type="submit" value="Send" disabled={isSubmitting}>
          {t('contactUsSendButton')}
        </button>

        {stateMessage && <p>{stateMessage}</p>}
      </form>
    </section>
  );
};
export default ContactForm;
