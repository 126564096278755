import React, { useState } from 'react';
import './Switch.css';
import { useTranslation } from 'react-i18next';

function SwitchToggle() {
  const [toggled, setToggled] = useState(false);
  let { i18n } = useTranslation();

  return (
    <div className="switch-container">
      <button
        className={`toggle-btn ${toggled ? 'toggled' : ''}`}
        onClick={() => {
          setToggled(!toggled);
          !toggled ? i18n.changeLanguage('fr') : i18n.changeLanguage('en');
        }}
      >
        <div className="thumb"></div>
        <h5 className={`lang-btn-${!toggled ? 'en' : 'fr'}`}>
          {!toggled ? 'FR' : 'EN'}
        </h5>
      </button>
    </div>
  );
}

export default SwitchToggle;
