import React from 'react';
import '../../App.css';
import ContactForm from '../ContactForm';
import Footer from '../Footer';
import { useTranslation } from 'react-i18next';
function ContactUs() {
  const { t } = useTranslation();
  return (
    <>
      <div className="contact-us">
        <h1 className="page-title">{t('contactUsTitle')}</h1>
      </div>
      <ContactForm />
      <Footer />
    </>
  );
}
export default ContactUs;
